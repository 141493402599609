<template>
    <div>
        <div class="text-xs-center">
            <v-layout row wrap>
                <v-flex xs12 sm12 md6  >
                    <v-select
                        @change="getItems()"
                        class="pt-0 mt-3 pr-1" color="teal"
                        v-model="productCategory" ref="productCategory"
                        item-text="name" dense small
                        item-value="id"
                        :items="productCategories"
                        label="Select Product Category"
                    ></v-select>
                </v-flex>

                <v-flex xs10 sm10 md5>
                    <v-text-field
                        v-model="search" ref="search" class="pt-0 mt-3 pl-1" 
                        label="Search Item (name, category, type)..."
                        required dense
                    ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 md1>
                    <v-btn fab small color="teal" @click="getSearchedItems()">
                        <i class="fas fa-search white--text"></i>
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>
        <v-layout row wrap>
            <v-flex xs12 class="mt-4 pt-2 text-xs-center" v-if="mostBoughtItems.length == 0">
                <h3>No Products Found <span v-if="search.length > 0">For This Search <b class="red--text">"{{ search }}"</b></span></h3>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 v-for="item in mostBoughtItems" :key="item.id">
                <v-hover>
                    <v-card color="white" slot-scope="{ hover }" :class=" `ma-1 elevation-${hover ? 12 : 2}`"  :to="'/market_place/items/'+item.id" style="border-radius: 5px">
                        <v-container fluid grid-list-lg>
                        <v-layout row wrap>
                            <v-flex xs12 sm5 md4 class="pa-0 ma-0">
                                <v-img
                                    :src="item.get_image"
                                    height="150px" 
                                    contain 
                                ></v-img>
                            </v-flex>
                            <v-flex xs12 sm7 md8 class=" ma-0 pa-0">
                                <div class="ml-1">
                                    <div class="title ma-0 pa-0 text-xs-left"><u>{{item.name| truncate(23, '...') }}</u></div>
                                    <div>
                                        <v-layout row wrap class=" ma-0 pa-0">
                                            <v-flex xs12 text-xs-left class=" ma-0 pt-2 pb-0 px-0">
                                                <span class="dark--text font-weight-bold">Description:</span> {{ item.description| truncate(55, '...')  }}
                                            </v-flex>
                                            <v-flex xs8 text-xs-left class=" ma-0 pa-0">
                                                <span class="dark--text font-weight-bold">Cost:</span><span> {{item.price}} FCFA</span>
                                            </v-flex>
                                            <v-flex xs4 text-xs-right class=" ma-0 pa-0">
                                                <span class="dark--text font-weight-bold">Qty:</span><span> {{item.quantity}}</span>
                                            </v-flex>
                                            <v-flex xs6 text-xs-left class=" ma-0 pa-0">
                                                <span class="dark--text font-weight-bold">In-Stock:</span><span> {{item.quantity}}</span>
                                            </v-flex>
                                            <v-flex xs6 text-xs-right class=" ma-0 pa-0">
                                                <span class="dark--text font-weight-bold">Type:</span><span> {{item.category}}</span>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        </v-container>
                    </v-card>
                </v-hover>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                search: '',
                productCategory: '1',
                productCategories: [],
                mostBoughtItems: [],
                // no_items_flag: false,
            }
        },

        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Market Place"
            this.getItems()
            this.getProductCategories()
            this.$store.commit('setCloseURL', "/market_place")
        },
        methods:{
            async getProductCategories(){
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/market_place/get/products/categories/')  // best items
                    .then(response => {
                        this.productCategories = response.data  // get the data and fill into most Bought Items
                        this.productCategory = response.data[0]["id"]  // get the data and fill into most Bought Items
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getItems(){
                this.$store.commit('setIsLoading', true)

                const type = this.$route.params.type  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/products/'+this.productCategory+'/'+type+'/info/')  // best items
                    .then(response => {
                        this.mostBoughtItems = response.data  // get the data and fill into most Bought Items
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getSearchedItems(){
                if(this.search == ""){
                    this.$store.commit('setSnackBarMessage', "Please input search item")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.search.focus()  // this causes a an auto fucos to the element
                }
                else{
                    this.$store.commit('setIsLoading', true)
                    const type = this.$route.params.type  // get campaign category from url and send request to server
                    await axios
                        .get('/api/v1/market_place/get/products/'+this.productCategory+'/'+type+'/'+this.search+'/info/')  // best items
                        .then(response => {
                            this.mostBoughtItems = response.data  // get the data and fill into most Bought Items
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }
            },
            
        },

        watch: {
            '$route': function(to, from) {
                this.getItems()
            }
        },

    }
</script>

<style>
.theme--light.v-messages {
    display: none;
}
</style>